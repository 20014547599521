import { navigate } from "gatsby"
import React from "react"
import ArrowLeftIcon from "../assets/icons/Arrow-left.svg"
import NavigationButton from "./NavigationButton"

export default function GoBackButton() {
  const onClick = () => {
    navigate(-1)
  }

  return (
    <NavigationButton onClick={onClick} position="left">
      <ArrowLeftIcon />
    </NavigationButton>
  )
}
