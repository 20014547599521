import { navigate } from "gatsby"
import React, { Dispatch } from "react"
import { useDispatch } from "react-redux"
import { SearchModeActions } from "../actions"
import FilterIcon from "../assets/icons/Filter.svg"
import i18n, { Lang } from "../utils/i18n"
import NavigationButton from "./NavigationButton"

type FilterButtonProps = {
  lang: Lang
}

export default function FilterButton(props: FilterButtonProps) {
  const searchModeDispatch = useDispatch<Dispatch<SearchModeActions>>()

  const onClick = () => {
    searchModeDispatch({ type: "SEARCH_MODE_FILTER_SET" })
    window.scrollTo(0, 0)
    navigate(`/${props.lang}/${i18n[props.lang].paths.search}`)
  }

  return (
    <NavigationButton onClick={onClick} position="right">
      <FilterIcon />
    </NavigationButton>
  )
}
