import React, { ReactNode } from "react"

type NavigationButtonProps = {
  children: ReactNode
  position: "left" | "right"
  onClick: () => void
}

export default function NavigationButton(props: NavigationButtonProps) {
  const { children, position, onClick } = props

  const horizontalPosition =
    position === "left" ? "left-gutter" : "right-gutter"

  return (
    <button
      onClick={onClick}
      className={`fixed bottom-gutter rounded-2xl bg-white shadow-lift w-12 h-12 leading-12 ${horizontalPosition} flex justify-center items-center`}
    >
      {children}
    </button>
  )
}
