import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import { Link } from "gatsby"
import React from "react"
import { Service } from "../types"

export default function ServiceCard(props: { service: Service }) {
  const { service } = props

  const tags = service.activities
    .map(activity => activity.name)
    .concat(service.profiles.map(profile => profile.name))
    .slice(0, 5)

  const hasMoreTags = service.activities.length + service.profiles.length > 5

  return (
    <Link
      to={linkResolver(service)}
      className="no-underline text-black block w-full"
    >
      <span className="truncate text-darkgray text-sm tracking-tight">
        {service.serviceProvider?.name ?? "\u200b"}
      </span>
      <h2 className="truncate -mt-2">{service.title}</h2>
      <div
        className="bg-white rounded-xl h-32 px-4 py-2 shadow-lg bg-center bg-cover"
        key={`service-${service.uid}`}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(247,203,88,0.8) 0%, rgba(255,255,255,0) 40%), url("${service.image.url}")`,
        }}
      >
        <div className="relative w-full h-full">
          <p className="text-xs absolute bottom-0">
            {tags.map(tag => (
              <span key={`tag-${tag}`} className="pr-1">
                #{tag}
              </span>
            ))}
            {hasMoreTags && "..."}
          </p>
        </div>
      </div>
    </Link>
  )
}
