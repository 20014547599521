import React from "react"
import { Service } from "../types"
import i18n, { Lang } from "../utils/i18n"
import ServiceCard from "./ServiceCard"

export default function ServiceList(props: {
  services: Service[]
  lang: Lang
}) {
  const { services, lang } = props

  return (
    <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-4">
      {services.length > 0 ? (
        services.map(service => (
          <ServiceCard key={`service-${service.uid}`} service={service} />
        ))
      ) : (
        <h3>{i18n[lang].filter.noResults}</h3>
      )}
    </div>
  )
}
